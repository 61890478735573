<template>
  <div
    id="cv"
    class="tab-pane"
    aria-labelledby="cv-tab"
    role="tabpanel"
  >
    <div class="card">
      <div class="card-header">
        <h3 class="cart-title">
          <a data-action="collapse">{{ title }}</a></h3>
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <a data-action="collapse"><i data-feather="chevron-down" /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div
                v-if="type == 2"
                class="alert alert-warning p-1"
              >
                CV to be shown in your ICREA’s web page
              </div>
              <div
                v-if="type == 1 && secondary"
                class="alert alert-warning p-1"
              >
                CV to be shared with the evaluators
              </div>
              <!-- <FILE UPLOADED> -->
              <div
                v-for="(file, index) in loggedUser.CVFile.filter(e => type == 1 ? e.type == 5 : e.type == 6)"
                :key="index"
                class="alert alert-success"
              >
                <div class="alert-body">
                  <div class="row d-flex align-items-center">
                    <div class="col-sm-6">
                      <p class="text-secondary">
                        File uploaded
                      </p>
                      <p class="text-dark">
                        <strong>{{ file.name }}</strong>
                      </p>
                      <p class="text-dark">
                        <small><em>Last update: {{ file.created_at }}</em></small>
                      </p>
                    </div>
                    <div class="col-sm-6 d-flex justify-content-sm-end">
                      <p>
                        <a
                          :href="file.url"
                          title="open file"
                          target="_blank"
                          class="btn text-dark"
                        ><i data-feather="eye" /> View CV</a>
                        <button
                          id="confirm-text"
                          type="button"
                          class="btn text-danger"
                          title="delete file"
                          @click="deleteFile(file.id)"
                        >
                          <i data-feather="trash-2" /> Delete CV
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </FILE UPLOADED> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group required mb-3">
                <label
                  for=""
                  class="form-label"
                />
                <vue-dropzone
                  id="dropzone"
                  ref="myVueDropzone"
                  class="dropzone-files"
                  :options="dropzoneOptions"
                />
              </div>
            </div>
          </div>
          <div
            v-if="type == 1"
            class="form-check form-check-success form-switch d-flex align-items-center mb-1 col-5"
          >
            <input
              id="publishContent"
              type="checkbox"
              :checked="secondary"
              class="form-check-input"
              @click="$emit('hideCv')"
            >
            <label
              class="form-check-label"
              for="publishContent"
            ><small class="ms-1">{{ labels['hide_cv'] }}</small></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    loggedUser: { type: Object, required: true, default: () => {} },
    title: { type: String, required: true },
    type: { type: Number, required: true },
    secondary: { type: Boolean, required: true },
  },
  data() {
    return {
      labels: [],
      badge_class: 'success',
      dropzoneOptions: {
        thumbnailWidth: 150,
        acceptedFiles: 'application/pdf',
        maxFilesize: 25,
        maxFiles: 1,
        url: `${Vue.prototype.$groupUrl}/users-files`,
        params: {
          user_id: this.loggedUser.id,
          type: this.type,
        },
        uploadMultiple: false,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessTokenAdmin')}` },
        success: async () => {
          await this.$store.dispatch('users/fetchUserCVs', this.loggedUser.id)
          this.loggedUser.CVFile = this.userCVs
          this.$toastr.success('', 'File uploaded successfully!')
        },
        error(file, message) {
          Vue.swal('File not uploaded', message, 'warning')
          this.removeAllFiles()
        },
        maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      userCVs: 'users/userCVs',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.cv`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async deleteFile(fileId) {
      await this.$store.dispatch('users/deleteFile', fileId)
      this.$toastr.success('', 'File deleted successfully!')
      this.loggedUser.CVFile.splice(this.loggedUser.CVFile.findIndex(x => x.id === fileId), 1)
      this.$refs.myVueDropzone.removeAllFiles()
    },
  },
}
</script>

<style>

</style>
